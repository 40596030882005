import lazySizes from 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';

function config() {
  lazySizes.cfg.init = false;

  // calculated visual viewport area in all directions,
  // so that elements can be loaded before they become visible
  const innerWidth = window.innerWidth;
  // mobile
  if (innerWidth < 600) {
    lazySizes.loadMode = 1;
    // lazySizesConfig.expand = 100;
    // lazySizesConfig.expFactor = 1.25;
    // tablet
  } else if (innerWidth > 600 && innerWidth < 900) {
    lazySizesConfig.expand = 600;
    // desktop
  } else if (innerWidth > 900) {
    lazySizesConfig.expand = 900;
  }

  window.lazySizesConfig = lazySizes.cfg;
}

function init() {
  document.addEventListener('lazybeforeunveil', function (e) {
    const bg = e.target.getAttribute('data-bg');
    const imageSet = e.target.getAttribute('data-imageset');
    if (imageSet) {
      e.preventDefault();
      const inlineStyles = e.target.getAttribute('style');
      e.target.setAttribute(
        'style',
        `${inlineStyles ?? ''} background-image: url("${bg}"); background-image: image-set(${imageSet}); background-image: -webkit-image-set(${imageSet})`.trim()
      );
    }
  });

  const images = [...document.getElementsByTagName('img')];
  const iframes = [...document.querySelectorAll('iframe[data-src]')];

  // For Puppeteer-Crawler: manually load all images ASAP
  if (navigator.userAgent === 'Puppeteer-Crawler/1.0') {
    images.forEach((image) => image.classList.add('lazypreload'));
    const bgImages = [].slice.call(document.querySelectorAll('[data-bg]'));
    bgImages.forEach((image) => {
      image.style.backgroundImage = `url("${image.dataset.bg}")`;
      image.removeAttribute('data-bg');
    });

    lazySizes.init();
    return;
  }

  if (images.length > 0) {
    images.forEach((image) => {
      // Skip priority images
      const isPriority = image.getAttribute('data-priority') === 'true';
      if (isPriority) {
        return;
      }

      // If image is part of a Swiper slideshow, do nothing.
      // We want to use the Swiper lazyloading option because we don't want
      // to see swiper images until swiper script and controls load.
      if (image.closest('.swiper-slide') !== null) {
        return;
      }
      image.classList.add('lazyload')
    });
  }

  if (iframes.length > 0) {
    iframes.forEach((iframe) => iframe.classList.add('lazyload'));
  }

  lazySizes.init();
}


export default { init, config };
